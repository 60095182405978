
















































































import { defineComponent, PropType } from 'vue';

import { ICategory, IProduct } from '../types';

import { appStoreMapper } from '@/store/appstore';
import { catalogStoreMapper } from '../store';
import { showErrorDialog } from '@/misc';
import bus from '@/bus';
import { getImageThumbnailPath } from '@/utils';

import CatalogItem from '@/components/CatalogItem.vue';
import ContentBlock from 'src/components/content.vue';

export default defineComponent({
  name: 'Category',
  components: {
    CatalogItem,
    ContentBlock,
  },
  props: {
    categoryId: {
      type: String as PropType<string>,
      required: true,
    },
  },
  data: () => ({
    category: null as Nullable<ICategory>,
    products: [] as IProduct[],
    productsLoaded: false,
    loading: false,
    offset: 0,
    limit: 10,
    ptrIgnore: false,
  }),
  created() {
    this.getCategory();
  },
  computed: {
    preloaderClasses(): any[] {
      return [
        (this as any).$style.preloader,
        {
          bottom:
            this.category?.sub_categories.length ||
            (!this.productsLoaded && this.products.length),
        },
      ];
    },
    showPreloader(): boolean {
      return !this.productsLoaded && this.loading;
    },
    showBar(): boolean {
      return !!this.settings.showBasket && !!this.totalProducts;
    },
    ...appStoreMapper.mapGetters(['decimalOptions', 'fullImagePath', 'deviceWidth']),
    ...catalogStoreMapper.mapGetters(['settings', 'totalProducts', 'receiveBranchId']),
    ...catalogStoreMapper.mapState([
      'basket',
      'categories',
      'categoriesScrollTop',
      'searchCache',
    ]),
  },
  methods: {
    // Встроенные механизм не работает
    restoreScrollTop() {
      if (this.category) {
        const element = (this.$refs.page as unknown as Vue).$el.querySelector(
          '.page-content',
        ) as HTMLElement;
        const savedScrollTop = this.categoriesScrollTop[this.category.id];

        setTimeout(() => {
          element.scrollTop = savedScrollTop;
        });
      }
    },
    pageBeforeIn() {
      this.setInBasket(false);
      this.setInForm(false);
      this.setInBonus(false);
    },
    // Нужны оба события
    pageAfterIn() {
      this.restoreScrollTop();
    },
    // Нужны оба события
    pageInit() {
      this.restoreScrollTop();
    },
    pageBeforeOut() {
      if (this.category) {
        const scrollTop = (
          (this.$refs.page as unknown as Vue).$el.querySelector(
            '.page-content',
          ) as HTMLElement
        ).scrollTop;

        this.setCategoriesScrollTop({ id: this.category.id, value: scrollTop });
      }
    },
    getCategoryImage(category: ICategory): string {
      if (!category.photo) return '';

      return this.fullImagePath(
        getImageThumbnailPath(category.photo, { deviceWidth: this.deviceWidth }),
      );
    },
    getProductImage(product: IProduct): string {
      if (!product.images[0]) return '';

      return this.fullImagePath(
        getImageThumbnailPath(product.images[0], { deviceWidth: this.deviceWidth }),
      );
    },
    getProductAmountInBasket(product: IProduct): number {
      return this.basket.find((entry) => entry.product.id === product.id)?.amount || 0;
    },
    getProductMaxAmount(product: IProduct): Nullable<number> {
      return this.settings.amountControl
        ? product.amounts?.find((entry) => entry.branch_id === this.receiveBranchId)
            ?.amount ?? 0
        : undefined;
    },
    onProductUpdateAmount({
      product,
      $event: amount,
    }: {
      product: IProduct;
      $event: number;
    }) {
      if (this.getProductAmountInBasket(product) !== amount) {
        this.addToBasket({
          amount: amount - this.getProductAmountInBasket(product),
          product,
        });
      }
    },
    findCategoryById({
      categoryId,
      subCategories,
    }: {
      categoryId: number;
      subCategories?: ICategory[];
    }): Nullable<ICategory> {
      const subs = subCategories || this.categories;
      let foundCategory: Nullable<ICategory>;

      subs.forEach((category) => {
        if (!foundCategory) {
          if (category.id === categoryId) {
            foundCategory = category;
          }

          if (!foundCategory && category.sub_categories.length) {
            foundCategory = this.findCategoryById({
              categoryId,
              subCategories: category.sub_categories,
            });
          }
        }
      });

      return foundCategory;
    },
    getCategory() {
      this.category = this.findCategoryById({
        categoryId: parseInt(this.categoryId, 10),
      });
    },
    goToProduct(product: IProduct) {
      bus.$emit('product-popup', product);
    },
    loadProducts({ reset }: { reset?: boolean } = {}) {
      if (!this.category) {
        return Promise.resolve([]);
      }

      this.loading = true;
      //this.$f7.preloader.show();

      return this.getProductsByCategoryId({
        category: this.category.id,
        limit: this.limit,
        offset: this.offset,
        reset,
      })
        .then((products) => {
          this.products = products as IProduct[];

          if (
            (products as IProduct[]).length < this.limit ||
            !(products as IProduct[]).length
          ) {
            this.productsLoaded = true;
          }

          this.$nextTick(() => {
            this.$f7?.lazy.create(this.$el as HTMLElement);
          });

          return products;
        })
        .catch((e) => {
          showErrorDialog.call(this, e);
        })
        .finally(() => {
          this.loading = false;
          //this.$f7.preloader.hide();
        });
    },
    onInfinite(): void {
      if (this.category && !this.loading && !this.productsLoaded) {
        const nextOffset = this.offset + this.limit;

        this.loading = true;

        this.getProductsByCategoryId({
          category: this.category.id,
          limit: this.limit,
          offset: nextOffset,
        })
          .then((products) => {
            this.products = this.products.concat(products as IProduct[]);
            this.offset = nextOffset;

            if (
              (products as IProduct[]).length < this.limit ||
              !(products as IProduct[]).length
            ) {
              this.productsLoaded = true;
            }

            this.$nextTick(() => {
              this.$f7?.lazy.create(this.$el as HTMLElement);
            });
          })
          .catch((e) => {
            showErrorDialog.call(this, e);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    async onPTRRefresh(done: () => void) {
      this.productsLoaded = false;
      this.offset = 0;

      await this.getCategories();

      this.getCategory();

      this.loadProducts({ reset: true }).then(() => {
        done();
      });
    },
    goToCategory(category: ICategory) {
      this.$f7?.views.current.router.navigate(`/catalog/category/${category.id}`);
    },
    ...catalogStoreMapper.mapActions([
      'addToBasket',
      'getProductsByCategoryId',
      'getCategories',
    ]),
    ...catalogStoreMapper.mapMutations([
      'setCategoriesScrollTop',
      'setInBasket',
      'setInForm',
      'setInBonus',
    ]),
  },
  watch: {
    category() {
      this.loadProducts();
    },
  },
});

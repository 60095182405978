
import Vue from 'vue';

import { slotSeeker } from '@/utils';

export default Vue.extend({
  name: 'FeedbackSheetSlot',
  functional: true,
  render() {
    return slotSeeker('feedback-sheet-slot', { props: { sheet: true } });
  },
});





































import { Framework7Params } from 'framework7/components/app/app-class';
import { AppData } from 'src/types';

import { defineComponent, SetupContext, computed } from 'vue';
import _merge from 'lodash/merge';

import bus from 'src/bus';
import i18n from 'src/translate/lang';
import config from 'src/app.config.js';
import packageJson from '../package.json';
import { appStoreMapper, useAppStore } from 'src/store/appstore';
import {
  LOCALSTORAGE_APPDATA,
  LOCALSTORAGE_DEVICE,
  LOCALSTORAGE_DOMAIN,
  LOCALSTORAGE_DOMAIN_FIRST,
  LOCALSTORAGE_DOMAIN_TOKENS,
  LOCALSTORAGE_SECONDRUN,
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_STAT_QUEUE,
  LOCALSTORAGE_LOCALE,
  LOCALSTORAGE_HOST,
  LOCALSTORAGE_UUID,
  LOCALSTORAGE_PUSHTOKEN,
} from 'src/misc';
import Store from './storage';

import AppModules from 'src/components/app-modules.vue';
import Debug from 'src/components/debug.vue';
import Panel from 'src/components/panel.vue';
import Tabs from 'src/components/tabs.vue';
import ListInputMultiplePopup from 'src/components/fields/list-input-multiple-popup.vue';
import WelcomeScreen from 'src/components/WelcomeScreen.vue';
import PWA from 'src/components/PWA.vue';
import PWADebug from 'src/components/PWADebug.vue';
import DebugControl from '@/components/DebugControl.vue';

import { appDataUpdater, backButton, splashScreen, swipeWatcher } from './composition';
import { sendStatQueue } from '@/misc';

export default defineComponent({
  name: 'App',
  components: {
    PWADebug,
    DebugControl,
    AppModules,
    Debug,
    ListInputMultiplePopup,
    Panel,
    Tabs,
    WelcomeScreen,
    PWA,
  },
  data: () => ({
    appShowed: false,
  }),
  setup(props: any, context: SetupContext) {
    appDataUpdater();
    swipeWatcher();

    const appStore = useAppStore();
    const { showSysMsg } = backButton();
    const { hideSplash, tabs } = splashScreen();

    const f7params = computed(
      () =>
        _merge(config.framework7, {
          routes: window.routes || [],
          id: packageJson.name,
          dialog: {
            buttonCancel: i18n.t('global.dialog.cancel').toString(),
            buttonOk: i18n.t('global.dialog.ok').toString(),
          },
          statusbar: {
            enabled: !(config.app.miniapp || appStore.getters.isPWA),
            iosOverlaysWebView: !(config.app.miniapp || appStore.getters.isPWA),
            androidOverlaysWebView: false,
            ...config.theme,
          },
          on: {
            init: hideSplash,
          },
        }) as Framework7Params,
    );

    return {
      showSysMsg,
      hideSplash,
      tabs,
      f7params,
    };
  },
  created() {
    let appDataFromStorage: AppData = Store.getSync(LOCALSTORAGE_APPDATA);

    if (
      !appDataFromStorage ||
      (typeof appDataFromStorage === 'object' &&
        Object.keys(appDataFromStorage) &&
        !appDataFromStorage.settings)
    ) {
      appDataFromStorage = {} as any;
    }

    this.setAppData(appDataFromStorage);

    if (Object.keys(appDataFromStorage).length) {
      this.setAppDataDidLoad(true);
      bus.$emit('appDataDidLoad');
    }
    this.setAuthToken(
      Store.getSync(LOCALSTORAGE_TOKEN) ||
        localStorage.getItem(`${packageJson.name}-token`) ||
        '',
    );
    this.setIdDevice(Store.getSync(LOCALSTORAGE_DEVICE) || 0);
    this.setSecondRun(Store.getSync(LOCALSTORAGE_SECONDRUN) || false);

    this.setCurrentTab(
      appDataFromStorage?.settings?.modules?.app?.defaultTab || config.modules.defaultTab,
    );
    this.setTabDidOpen(
      appDataFromStorage?.settings?.modules?.app?.defaultTab || config.modules.defaultTab,
    );

    this.setHost(Store.getSync(LOCALSTORAGE_HOST) || 'client');

    this.setDomain(
      !window.devMode || this.host === 'client'
        ? Store.getSync(LOCALSTORAGE_DOMAIN) || config.api.client
        : this.host === 'gmb'
        ? 'gmb'
        : 'front',
    );
    this.setDomainFirstSelect(Store.getSync(LOCALSTORAGE_DOMAIN_FIRST));
    this.setDomainTokens(Store.getSync(LOCALSTORAGE_DOMAIN_TOKENS) || {});

    this.setStatQueue(Store.getSync(LOCALSTORAGE_STAT_QUEUE) || []);

    this.changeLocale(Store.getSync(LOCALSTORAGE_LOCALE));

    this.setUuid(Store.getSync(LOCALSTORAGE_UUID));

    this.setPushToken(Store.getSync(LOCALSTORAGE_PUSHTOKEN));
  },
  mounted() {
    this.$f7ready?.((f7) => {
      if (f7.device.cordova) {
        this.handleKeyboard();
        this.detectPWA();
      }

      // Sometimes tabbar hidden at startup. Why?
      this.startTabbarWatcher();
      bus.$on('domain-switch', () => {
        this.startTabbarWatcher();
      });
    });

    setTimeout(() => {
      this.appShowed = true;
    }, 8000);

    bus.$once('appDataDidLoad', () => {
      const relatedModuleActivated =
        this.appData.settings.modules.related &&
        this.appData.settings.modules.related.list.length > 1;

      if (!this.secondRun && !config.app?.miniapp && !relatedModuleActivated) {
        bus.$emit('showWelcomeScreen');
      }
    });

    sendStatQueue(this.$store as any);
  },
  computed: {
    style(): object {
      return {
        visibility:
          this.appShowed ||
          this.appDataDidLoad ||
          this.appDataDidFail ||
          this.regDeviceDidFail
            ? 'visible'
            : 'hidden',
        '--app-global-catalog-item-lines-min': this.appSettings.catalogItemLinesMin,
        '--app-global-catalog-item-lines-max': this.appSettings.catalogItemLinesMax,
        '--app-global-window-height-diff': this.isPWA
          ? `${window.outerHeight - window.innerHeight}px`
          : '0px',
      };
    },
    ...appStoreMapper.mapGetters(['appSettings', 'miniapp', 'isPWA', 'extraLogo']),
    ...appStoreMapper.mapState([
      'appData',
      'appDataDidFail',
      'appDataDidLoad',
      'domainFirstSelect',
      'regDeviceDidFail',
      'secondRun',
      'locale',
      'host',
    ]),
  },
  methods: {
    startTabbarWatcher() {
      setTimeout(() => {
        let cancelTrackVisibilityCount = 0;
        const tabbarElement = document.querySelector('.views .tabbar');
        const trackTabbarVisibility = setInterval(() => {
          const isTabbarVisible: boolean =
            !tabbarElement?.classList.contains('toolbar-hidden');

          if (isTabbarVisible || cancelTrackVisibilityCount > 20) {
            clearInterval(trackTabbarVisibility);
          }

          if (tabbarElement && !isTabbarVisible) {
            this.$f7.toolbar.show('.views .tabbar');
          }

          cancelTrackVisibilityCount++;
        }, 200);
      }, 500);
    },
    handleKeyboard() {
      if (!window.Keyboard || !window.Keyboard.shrinkView) return;

      const $ = this.$f7?.$;
      let iOSVersion = 16; // По умолчанию 16, т.к. после 17 все сломалось

      if (/iP(hone|od|ad)/.test(navigator.platform)) {
        const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
        if (v) {
          iOSVersion = parseInt(v[1], 10);
        }
      }

      if (this.$theme.ios && iOSVersion >= 17) {
        window.Keyboard.shrinkView(true);
      } else {
        window.Keyboard.shrinkView(false);
      }
      window.Keyboard.disableScrollingInShrinkView(true);
      // window.Keyboard.hideFormAccessoryBar(true);

      window.addEventListener('keyboardWillShow', () => {
        document.documentElement.classList.add('tabbar-force-hidden');

        this.$f7?.input.scrollIntoView(
          document.activeElement as HTMLElement,
          0,
          true,
          true,
        );
      });

      window.addEventListener('keyboardDidShow', () => {
        const element = document.activeElement as HTMLInputElement;

        this.$f7?.input.scrollIntoView(element, 0, true, true);

        if (iOSVersion < 17) {
          if (element.nodeName === 'INPUT') {
            setTimeout(() => {
              element.disabled = true;

              setTimeout(() => {
                element.disabled = false;
              }, 10);
            }, 200);
          }
        }
      });

      window.addEventListener('keyboardDidHide', () => {
        if (
          document.activeElement &&
          $?.(document.activeElement).parents('.messagebar').length
        ) {
          return;
        }
        window.Keyboard.hideFormAccessoryBar(false);
        document.documentElement.classList.remove('tabbar-force-hidden');
      });

      window.addEventListener('keyboardHeightWillChange', (event: any) => {
        const keyboardHeight = event.keyboardHeight;

        if (iOSVersion < 17) {
          if (keyboardHeight > 0) {
            document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
            document.body.style.setProperty('--keyboard-height', `${keyboardHeight}px`);
            $?.('html').addClass('device-with-keyboard');
          } else {
            document.body.style.height = '';
            document.body.style.setProperty('--keyboard-height', '0px');
            $?.('html').removeClass('device-with-keyboard');
          }
        }
      });

      $?.(document).on(
        'touchstart',
        'input, textarea, select',
        function (e: any) {
          const nodeName = e.target.nodeName.toLowerCase();
          const type = e.target.type;
          const showForTypes = ['datetime-local', 'time', 'date', 'datetime'];

          if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
            window.Keyboard.hideFormAccessoryBar(false);
          } else {
            window.Keyboard.hideFormAccessoryBar(true);
          }
        },
        true,
      );

      // Scroll bug in android
      if (this.$theme.md) {
        ((this.$refs.app as unknown as Vue).$el as HTMLElement).addEventListener(
          'scroll',
          () => {
            ((this.$refs.app as unknown as Vue).$el as HTMLElement).scrollTop = 0;
          },
        );
      }
    },
    detectPWA() {
      // @ts-ignore
      if (this.isPWA) {
        document.documentElement.classList.add('pwa');

        if (document.documentElement.classList.contains('device-desktop')) {
          window.resizeTo(500, 950);

          window.addEventListener('resize', () => {
            const widthDiff = window.innerWidth - 500;
            const heightDiff = window.innerHeight - 950;

            window.resizeBy(-widthDiff, -heightDiff);
          });
        }
      }
    },
    ...appStoreMapper.mapMutations([
      'setAppData',
      'setAuthToken',
      'setCurrentTab',
      'setIdDevice',
      'setSecondRun',
      'setTabDidOpen',
      'setAppDataDidLoad',
      'setDomain',
      'setDomainFirstSelect',
      'setDomainTokens',
      'setStatQueue',
      'setHost',
      'setUuid',
      'setPushToken',
    ]),
    ...appStoreMapper.mapActions(['changeLocale']),
  },
});

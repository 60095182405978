











































































import Vue from 'vue';

import { IProduct } from '../types';

import bus from 'src/bus';
import { catalogStoreMapper } from '../store';
import { appStoreMapper } from '@/store/appstore';
import { formatNumber } from '@/utils/formatNumber';
import getImageThumbnailPath from '@/utils/getImageThumbnailPath';

import ContentBlock from '@/components/content.vue';
import ItemBadges from '@/components/item-badges.vue';
import CatalogBarWrapper from './CatalogBarWrapper.vue';
import CatalogProductBar from './CatalogProductBar.vue';
import ProductProps from './ProductProps.vue';

export default Vue.extend({
  components: {
    CatalogBarWrapper,
    CatalogProductBar,
    ContentBlock,
    ItemBadges,
    ProductProps,
  },
  data: () => ({
    product: null as Nullable<IProduct>,
    amount: 0,
    scrollTop: 0,
    pageContentElement: null as Nullable<HTMLElement>,
    showPreview: true,
  }),
  destroyed() {
    bus.$off('product-popup', this.productPopup);
  },
  mounted() {
    bus.$on('product-popup', this.productPopup);

    this.pageContentElement = (
      (this.$refs.page as unknown as Vue).$el as HTMLElement
    ).querySelector('.page-content') as HTMLElement;

    if (this.pageContentElement) {
      this.pageContentElement.addEventListener('scroll', this.scrollHandler);
    }
  },
  beforeDestroy() {
    if (this.pageContentElement) {
      this.pageContentElement.removeEventListener('scroll', this.scrollHandler);
    }
  },
  computed: {
    photos(): string[] {
      const imagesUrls: string[] = [];

      this.product?.images.forEach((image) => {
        if (image) {
          imagesUrls.push(this.getImageById(image));
        }
      });

      return imagesUrls;
    },
    showHandler(): boolean {
      return this.scrollTop <= 5;
    },
    previewImage(): Nullable<string> {
      if (!this.product) return;

      const image = this.product.images[0];

      if (!image) return;

      const path = getImageThumbnailPath(image, { deviceWidth: this.deviceWidth });
      const url = this.fullImagePath(path);

      if (path && this.lazyLoadedImagesUrls.includes(url)) {
        return url;
      }

      return null;
    },
    productMaxAmount(): Nullable<number> {
      return this.settings.amountControl
        ? this.product?.amounts?.find((entry) => entry.branch_id === this.receiveBranchId)
            ?.amount ?? 0
        : undefined;
    },
    showBar(): boolean {
      return !!this.product?.showPrice && !!this.settings.showBasket;
    },
    ...appStoreMapper.mapGetters([
      'clientName',
      'fullImagePath',
      'decimalOptions',
      'deviceWidth',
    ]),
    ...appStoreMapper.mapState(['lazyLoadedImagesUrls']),
    ...catalogStoreMapper.mapGetters(['settings', 'receiveBranchId']),
    ...catalogStoreMapper.mapState(['basket', 'lazyLoadedImagesIds']),
  },
  methods: {
    formatNumber,
    productPopup(product: IProduct) {
      // Should first set amount and then product (see watch function)
      this.amount =
        this.basket.find((entry) => entry.product.id === product.id)?.amount || 0;

      this.$nextTick(() => {
        this.product = product;
        this.showPreview = !this.lazyLoadedImagesIds.includes(this.product.images[0]);

        this.$f7.popup.open('.popup-product');
      });
    },
    onPopupClosed() {
      this.amount = 0;
      this.product = null;

      this.scrollTop = 0;
      ((this.$refs.page as unknown as Vue).$el as HTMLElement).scrollTop = this.scrollTop;
    },
    getImageById(id: number): string {
      const path = getImageThumbnailPath(id, {
        size: this.deviceWidth,
      });
      return this.fullImagePath(path);
    },
    scrollHandler() {
      if (this.pageContentElement) {
        this.scrollTop = this.pageContentElement.scrollTop;
      }
    },
    onLoad(productOrId: Nullable<IProduct> | number) {
      let imageId =
        typeof productOrId === 'number' ? productOrId : productOrId?.images[0];
      if (imageId) {
        this.pushLazyLoadedImagesIds(imageId);
      }
      this.showPreview = false;
    },
    ...catalogStoreMapper.mapActions(['addToBasket']),
    ...catalogStoreMapper.mapMutations(['pushLazyLoadedImagesIds']),
  },
  watch: {
    product() {
      this.$nextTick(() => {
        const popup = (this.$refs.popup as unknown as Vue).$el as HTMLElement;

        // Magic 2px!
        popup.style.height =
          'calc(100% - (var(--f7-navbar-height) / 2) + 2px - var(--f7-safe-area-top))';
        popup.style.top =
          'calc((var(--f7-navbar-height) / 2) - 2px + var(--f7-safe-area-top))';
      });
    },
    amount(value: number, prev: number = 0) {
      const diff = value - prev;

      if (this.product && diff !== 0) {
        this.addToBasket({
          amount: Math.round(diff),
          product: this.product!,
        });
      }

      this.$f7.popup.close('.popup-product');
    },
  },
});

import { IModule } from 'src/types';

import Vue from 'vue';
import { registerModule } from 'vuex-smart-module';
import { faMailBulk, faCommentAlt } from '@fortawesome/pro-regular-svg-icons';
import { faCommentAlt as faCommentAltLight } from '@fortawesome/pro-light-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';

import * as Misc from 'src/misc';
import config from './feedback.conf.json';

import store from 'src/store';
import FeedbackStore from './store';

import Feedback from './components/feedback.vue';
import FeedbackList from './components/list.vue';
import FeedbackSheet from './components/feedback-sheet.vue';
import FeedbackWidget from './components/feedback-widget.vue';

library.add(faMailBulk, faCommentAlt, faCommentAltLight);

export function init() {
  Vue.component('feedback', Feedback);
  Vue.component('feedback-sheet', FeedbackSheet);
  Vue.component('feedback-widget', FeedbackWidget);

  const routes = [
    {
      component: Feedback,
      path: config.url,
    },
    {
      component: FeedbackList,
      path: config.url + 'list',
    },
  ];

  registerModule(store, ['feedback'], 'feedback/', FeedbackStore);

  Misc.registerModuleConfig(config as IModule);
  Misc.registerModuleRoutes(routes);
}
